<template>
	<div class="box">
		<div class="null-page" v-show="yes"></div>
		<el-card class="box-card">
			<div slot="header" class="clearfix">
				<span>申请企业授信</span>
			</div>
			<div v-loading="loading" class="ns-member-address-list">
				<el-form :model="formData" :rules="rules" ref="ruleForm" label-width="150px">
					<el-form-item label="企业名称" prop="name">
						<el-input v-model="formData.qiye_name" placeholder="企业名称" class="ns-len-input"></el-input>
					</el-form-item>
					<el-form-item label="营业执照照片" prop="name">
						<!-- 	<el-upload ref="upload" list-type="picture-card">
							<i class="el-icon-plus"></i>
						</el-upload> -->
						<el-upload ref="upload" :limit='1' :action="uploadActionUrl" list-type="picture-card"
							:file-list="img" :on-success=" 
								(file, fileList) => {
									return handleSuccess(file, fileList, index);
								}
							" :on-exceed="handleExceed">
							<!-- <img :src="img" alt=""> -->
							<i class="el-icon-plus"></i>
						</el-upload>
					</el-form-item>
					<el-form-item label="法人信息" prop="mobile">
						<el-input v-model="formData.faren_name" autocomplete="off" placeholder="法人信息"
							class="ns-len-input"></el-input>
					</el-form-item>
					<el-form-item label="联系人" prop="mobile">
						<el-input v-model="formData.link_name" autocomplete="off" placeholder="联系人"
							class="ns-len-input"></el-input>
					</el-form-item>
					<el-form-item label="授权操作人姓名" prop="mobile">
						<el-input v-model="formData.shouquan_name" autocomplete="off" placeholder="授权操作人姓名"
							class="ns-len-input"></el-input>
					</el-form-item>
					<el-form-item label="授权操作人手机号">
						<el-input v-model.trim="formData.shouquan_tel" autocomplete="off" placeholder="授权操作人手机号"
							class="ns-len-input"></el-input>
					</el-form-item>
					<el-form-item label="企业地址" prop="qiye_addr">
						<el-select v-if="addressShow" v-model="formData.province_id" placeholder="请选择省"
							@change="changeProvice(formData.province_id)">
							<el-option v-for="option in province" :key="option.id" :label="option.name"
								:value="option.id">{{ option.name }}</el-option>
						</el-select>
						<el-select v-if="addressShow" v-model="formData.city_id" placeholder="请选择市"
							@change="changeCity(formData.city_id)">
							<el-option v-for="option in city" :key="option.id" :label="option.name"
								:value="option.id">{{ option.name }}</el-option>
						</el-select>
						<el-select v-if="addressShow" v-model="formData.district_id" placeholder="请选择区/县"
							@change="changeDistrict(formData.district_id)">
							<el-option v-for="option in district" :key="option.id" :label="option.name"
								:value="option.id">{{ option.name }}</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="详细地址" prop="address">
						<el-input v-model.trim="formData.qiye_detailaddr" autocomplete="off" placeholder="定位到小区、街道、写字楼"
							class="ns-len-input"></el-input>
					</el-form-item>
					<el-form-item v-if="is_qiye!=0" label="状态" prop="address">
						{{shenhe_status==0?'待审核':shenhe_status==1?'已同意':shenhe_status==2?'已驳回':'--'}}
					</el-form-item>
					<el-form-item v-if="shenhe_status==2" label="驳回原因:" prop="address">
						{{formData.bohui_msg}}
					</el-form-item>
					<!-- 	<el-form-item label="是否默认">
						<el-radio-group v-model="formData.is_default">
							<el-radio :label="1">是</el-radio>
							<el-radio :label="0">否</el-radio>
						</el-radio-group>
					</el-form-item> -->
					<el-form-item v-if="shenhe_status!=1">
						<el-button type="primary"  size="medium" @click="saveAddress('ruleForm')">提交</el-button>
					</el-form-item>
				</el-form>
			</div>
		</el-card>
	</div>
</template>

<script>
	import {
		addressInfo,
		saveAddress,
		modifyuserinfo,
		qiyeinfo
	} from "@/api/member/member"
	import Config from '@/utils/config';
	import {
		getArea
	} from "@/api/address"
	export default {
		name: "address_edit",
		components: {},
		data() {
			let self = this
			var isMobile = (rule, value, callback) => {
				if (!value) {
					return callback(new Error("手机号不能为空"))
				} else {
					const reg = /^1[3|4|5|6|7|8|9][0-9]{9}$/

					if (reg.test(value)) {
						callback()
					} else {
						callback(new Error("请输入正确的手机号"))
					}
				}
			}

			var fullAddress = (rule, value, callback) => {
				if (self.formData.province_id) {
					if (self.formData.city_id) {
						if (self.formData.district_id) {
							return callback()
						} else {
							return callback(new Error("请选择区/县"))
						}
					} else {
						return callback(new Error("请选择市"))
					}
				} else {
					return callback(new Error("请选择省"))
				}
			}

			return {
				addressShow: true,
				is_qiye: 0,
				shenhe_status: '',
				img: [],
				uploadActionUrl: Config.baseUrl + '/api/upload/evaluateimg',
				dialogVisible: false,
				formData: {
					qiye_name: '',
					zhizhao_pic: '',
					faren_name: '',
					link_name: '',
					shouquan_name: '',
					qiye_addr: '',
					shouquan_tel: '',
					qiye_detailaddr: ''
				},
				addressValue: "",
				flag: false, //防重复标识
				defaultRegions: [],
				rules: {
					// name: [{
					// 	required: true,
					// 	message: "",
					// 	trigger: "blur"
					// }],
					// mobile: [{
					// 	required: true,
					// 	validator: isMobile,
					// 	trigger: "blur"
					// }],
					// address: [{
					// 	required: true,
					// 	message: "",
					// 	trigger: "blur"
					// }],
					// qiye_addr: [{
					// 	required: true,
					// 	validator: fullAddress,
					// 	trigger: "blur"
					// }]
				},
				province: [],
				city: [],
				district: [],
				pickerValueArray: [],
				multiIndex: [0, 0, 0],
				isInitMultiArray: false,
				// 是否加载完默认地区
				isLoadDefaultAreas: false,
				loading: true,
				yes: true
			}
		},
		created() {
			this.formData.id = this.$route.query.id
			this.getAddressDetail()
			// this.handleDefaultRegions()
			this.getDefaultAreas(0, {
				level: 0
			})
		},
		mounted() {
			let self = this;
			setTimeout(function() {
				self.yes = false
			}, 300)
			this.getQiyeInfo()
		},
		watch: {
			defaultRegions: {
				handler(arr, oldArr = []) {
					// 避免传的是字面量的时候重复触发
					if (arr.length !== 3 || arr.join("") === oldArr.join("")) return
					this.handleDefaultRegions()
				},
				immediate: true
			}
		},
		computed: {
			pickedArr() {
				// 进行初始化
				if (this.isInitMultiArray) {
					return [this.pickerValueArray[0], this.pickerValueArray[1], this.pickerValueArray[2]]
				}
				return [this.pickerValueArray[0], this.city, this.district]
			}
		},
		methods: {
			getQiyeInfo() {
				console.log('qiyeinfo')
				let that = this;
				qiyeinfo()
					.then(res => {
						console.log(res)
						console.log(res)
						console.log(res)
						// console.log(that.Config.baseUrl)
						// console.log(res.data.zhizhao_pic)
						that.formData = res.data;
						this.is_qiye = res.data.is_qiye;
						// console.log(this.Config.baseUrl)
						// console.log(res.data.zhizhao_pic)
						// this.img = ['https://shuaichang.cgwzm.com/' + res.data.zhizhao_pic];
						if(res.data.zhizhao_pic){
							this.img = [{
							         url: 'https://shuaichang.cgwzm.com/' + res.data.zhizhao_pic
							       }]
						}
						
						this.shenhe_status = res.data.shenhe_status;
						
						this.defaultRegions = [res.data.province_id, res.data.city_id, res.data.district_id]

					})
					.catch(err => {
						console.log(err)

					})

			},
			handleSuccess(file, fileList, index) {
				console.log(file.data.pic_path)
				this.formData.zhizhao_pic = file.data.pic_path
				console.log(fileList)
				console.log(index)
				console.log(this.img)
			},
			handleRemove(file, fileList, index) {
				let i = util.inArray(file.response.data.pic_path, this.imgList[index]);
				this.imgList[index].splice(i, 1);

				if (this.isEvaluate) {
					this.goodsEvalList[index].again_images = this.imgList[index].toString();
				} else {
					this.goodsEvalList[index].images = this.imgList[index].toString();
				}

				if (this.imgList[index].length < 6) {
					this.hide[index] = false;
				}
			},
			handleExceed(file, fileList) {
				// 图片数量大于6
				this.$message.warning('上传图片最大数量为1张');
			},
			/**
			 * 改变省
			 */
			changeProvice(id) {
				this.getAreas(id, data => (this.city = data))
				let obj = {}
				obj = this.province.find(item => {
					//这里的province就是上面遍历的数据源
					return item.id === id //筛选出匹配数据
				})
				this.formData.city_id = ""
				this.formData.district_id = ""
				this.formData.qiye_addr = obj.name // 设置选中的地址
			},
			/**
			 * 改变市
			 */
			changeCity(id) {
				this.getAreas(id, data => (this.district = data))
				let obj = {}
				obj = this.city.find(item => {
					//这里的province就是上面遍历的数据源
					return item.id === id //筛选出匹配数据
				})
				this.formData.district_id = ""
				this.formData.qiye_addr = this.formData.qiye_addr + "-" + obj.name
			},
			/**
			 * 改变区
			 */
			changeDistrict(id) {
				let obj = {}
				obj = this.district.find(item => {
					//这里的province就是上面遍历的数据源
					return item.id === id //筛选出匹配数据
				})
				this.formData.qiye_addr = this.formData.qiye_addr + "-" + obj.name
			},
			/**
			 * 获取地址信息
			 */
			getAddressDetail() {
				console.log('=======daz')

				addressInfo({
						id: this.formData.id
					})
					.then(res => {
						console.log('=======daz')
						console.log(res)
						console.log('=======daz')
						let data = res.data
						if (data != null) {
							this.formData.name = data.name
							this.formData.mobile = data.mobile
							this.formData.telephone = data.telephone
							this.formData.address = data.address
							this.formData.qiye_addr = data.qiye_addr
							this.formData.latitude = data.latitude
							this.formData.longitude = data.longitude
							this.formData.is_default = data.is_default
							this.formData.province_id = data.province_id
							this.formData.city_id = data.city_id
							this.formData.district_id = data.district_id
							this.defaultRegions = [data.province_id, data.city_id, data.district_id]
							this.getDefaultAreas(data.city_id, {
								level: 1
							})
						}
					})
					.catch(err => {
						console.log('=======daz')
						console.log(err)
						console.log('=======daz')
					})
			},
			// 异步获取地区
			getAreas(pid, callback) {
				getArea({
						pid: pid
					})
					.then(res => {
						if (res.code == 0) {
							var data = []
							res.data.forEach((item, index) => {
								data.push(item)
							})
							if (callback) callback(data)
						}
					})
					.catch(err => {})
			},

			/**
			 * 获取省市区列表
			 */
			getDefaultAreas(pid, obj) {
				getArea({
						pid: pid
					})
					.then(res => {
						if (res.code == 0) {
							var data = []
							var selected = undefined
							res.data.forEach((item, index) => {
								if (obj != undefined) {
									if (obj.level == 0 && obj.province_id != undefined) {
										selected = obj.province_id
									} else if (obj.level == 1 && obj.city_id != undefined) {
										selected = obj.city_id
									} else if (obj.level == 2 && obj.district_id != undefined) {
										selected = obj.district_id
									}
								}

								if (selected == undefined && index == 0) {
									selected = item.id
								}
								data.push(item)
							})

							this.pickerValueArray[obj.level] = data
							if (obj.level + 1 < 3) {
								obj.level++
								this.getDefaultAreas(selected, obj)
							} else {
								this.isInitMultiArray = true
								this.isLoadDefaultAreas = true
							}

							this.province = this.pickerValueArray[0]
						}
						this.loading = false
					})
					.catch(err => {
						this.loading = false
					})
			},

			/**
			 * 渲染默认值
			 */
			handleDefaultRegions() {
				var time = setInterval(() => {
					if (!this.isLoadDefaultAreas) return
					this.isInitMultiArray = false

					for (let i = 0; i < this.defaultRegions.length; i++) {
						for (let j = 0; j < this.pickerValueArray[i].length; j++) {
							this.province = this.pickerValueArray[0]

							// 匹配省
							if (this.defaultRegions[i] == this.pickerValueArray[i][j].id) {
								// 设置选中省
								this.$set(this.multiIndex, i, j)
								// 查询市
								this.getAreas(this.pickerValueArray[i][j].id, data => {
									this.city = data

									for (let k = 0; k < this.city.length; k++) {
										if (this.defaultRegions[1] == this.city[k].id) {
											// 设置选中市
											this.$set(this.multiIndex, 1, k)

											// 查询区县
											this.getAreas(this.city[k].id, data => {
												this.district = data

												// 设置选中区县
												for (let u = 0; u < this.district.length; u++) {
													if (this.defaultRegions[2] == this.district[u]
														.id) {
														this.$set(this.multiIndex, 2, u)
														this.handleValueChange({
															detail: {
																value: [j, k, u]
															}
														})
														break
													}
												}
											})

											break
										}
									}
								})
							}
						}
					}
					if (this.isLoadDefaultAreas) clearInterval(time)
				}, 100)
				// this.addressShow = true;
			},
			handleValueChange(e) {
				// 结构赋值
				let [index0, index1, index2] = e.detail.value
				let [arr0, arr1, arr2] = this.pickedArr
				let address = [arr0[index0], arr1[index1], arr2[index2]]

				this.formData.qiye_addr = ""
				for (let i = 0; i < address.length; i++) {
					if (this.formData.qiye_addr) {
						this.formData.qiye_addr = this.formData.qiye_addr + "-" + address[i].name
					} else {
						this.formData.qiye_addr = this.formData.qiye_addr + address[i].name
					}
				}
			},

			/**
			 * 保存地址
			 */
			saveAddress(formName) {
				console.log(this.formData);


				modifyuserinfo(this.formData)
					.then(res => {
						if (res.code == 0) {
							this.$message({
								message: res.message,
								type: "success"
							})
							this.getQiyeInfo()
						} else {
							this.flag = false
							this.$message({
								message: res.message,
								type: "warning"
							})
						}
					})
					.catch(err => {
						this.flag = false
						this.$message.error(err.message)
					})


				return
				this.$refs[formName].validate(valid => {
					if (valid) {
						var data = {
							name: this.formData.name,
							mobile: this.formData.mobile,
							telephone: this.formData.telephone,
							province_id: this.formData.province_id,
							city_id: this.formData.city_id,
							district_id: this.formData.district_id,
							community_id: "",
							address: this.formData.address,
							qiye_addr: this.formData.qiye_addr,
							latitude: this.formData.latitude,
							longitude: this.formData.longitude,
							is_default: this.formData.is_default
						}

						data.url = "add"
						if (this.formData.id) {
							data.url = "edit"
							data.id = this.formData.id
						}
						if (this.flag) return
						this.flag = true

						saveAddress(data)
							.then(res => {
								if (res.code == 0) {
									this.$router.push({
										path: "/member/delivery_address"
									})
								} else {
									this.flag = false
									this.$message({
										message: res.message,
										type: "warning"
									})
								}
							})
							.catch(err => {
								this.flag = false
								this.$message.error(err.message)
							})
					} else {
						return false
					}
				})
			}
		}
	}
</script>
<style lang="scss" scoped>
	.box {
		width: 100%;
		position: relative;
	}

	.null-page {
		width: 100%;
		height: 730px;
		background-color: #FFFFFF;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 9;
	}

	.el-card.is-always-shadow,
	.el-card.is-hover-shadow:focus,
	.el-card.is-hover-shadow:hover {
		box-shadow: unset;
	}

	.el-card {
		border: 0;
	}

	.ns-len-input {
		width: 350px;
	}

	.el-select {
		margin-right: 10px;
	}
</style>